<template>
  <div>
    <div v-if="show" class="invoice-box" id="invoice" :class="{[type]: true}">

      <table class="report-container" :direction="lang == 'en'? 'ltr':''" :style="lang == 'en'? lang_dir:{}">
        <thead :class="is_repeated_header ? 'report-header' : 'no-report-header'">
        <tr>
          <th class="report-header-cell">
            <div class="header-info">
              <main-header :print-type="type" :inner-data="data" :data-header="template" :show-q-r-code="false"></main-header>
            </div>
          </th>
        </tr>
        </thead>
        <tbody class="report-content">
        <tr>
          <td class="report-content-cell">
            <div class="main">
              <div class="d-flex">
                <div class="w-100" v-if="inContentTemp('invoice_code') && data.invoice_code">
                  <div class="green-cc">
                    <h5 class="color-1a5a4a mb-1 f-s-15px d-flex justify-content-between"><b>رقم الفاتورة</b> <b v-if="is_multi_language_label" style="display: block; direction: ltr;">Invoice No.</b></h5>
                    <p class="mb-0 space-latter-2">{{ data.invoice_code }}</p>
                  </div>
                </div>
                <div class="ml-2 mr-2 w-100" v-if="inContentTemp('reference_number') && data.reference_number">
                  <div class="green-cc">
                    <h5 class="color-1a5a4a mb-1 f-s-15px d-flex justify-content-between"><b>الرقم المرجعي</b> <b v-if="is_multi_language_label" style="display: block; direction: ltr;">PO No.</b></h5>
                    <p class="mb-0 space-latter-2">{{ data.reference_number }}</p>
                  </div>
                </div>
                <div class="ml-2 mr-2 w-100" v-if="inContentTemp('invoice_date') && data.invoice_date">
                  <div class="green-cc">
                    <h5 class="color-1a5a4a mb-1 f-s-15px d-flex justify-content-between"><b>تاريخ الفاتورة</b> <b v-if="is_multi_language_label" style="display: block; direction: ltr;">Invoice date</b></h5>
                    <p class="mb-0 space-latter-2">{{ data.invoice_date }}</p>
                  </div>
                </div>
<!--                <div class="ml-2 mr-2 w-100" v-if="inContentTemp('issue_date') && data.issue_date">-->
<!--                  <div class="green-cc">-->
<!--                    <h5 class="color-1a5a4a mb-1 f-s-15px d-flex justify-content-between"><b>تاريخ الإستحقاق</b> <b v-if="is_multi_language_label" style="display: block; direction: ltr;">Due date</b></h5>-->
<!--                    <p class="mb-0 space-latter-2">{{ data.issue_date }}</p>-->
<!--                  </div>-->
<!--                </div>-->
                <div class="ml-2 mr-2 w-100" v-if="inContentTemp('is_deferred')">
                  <div class="green-cc">
                    <h5 class="color-1a5a4a mb-1 f-s-15px d-flex justify-content-between"><b>نوع الفاتورة</b> <b v-if="is_multi_language_label" style="display: block; direction: ltr;">Invoice type</b></h5>
                    <p v-if="data.is_deferred" class="mb-0 space-latter-2">آجل</p>
                    <p v-else class="mb-0 space-latter-2">نقدي</p>
                  </div>
                </div>
              </div>

              <div class="d-flex">
                <div class="mt-2 w-100 margin-right-025rem">
                  <div class="d-flex justify-content-between">
                    <span class="color-1a5a4a f-s-15px mb-1"><b>معلومات الـبـــــائع</b></span>
                    <span class="color-1a5a4a f-s-15px mb-1" v-if="is_multi_language_label"><b>Seller information</b></span>
                  </div>

                  <div class="w-60per h-100">
                    <div class="green-cc-3">
                      <div class="d-flex">
                        <span class="par-h"></span>
                        <div>
                          <p class="f-s-15px mb-1" v-if="inContentTemp('business_name_ar')">{{ data.company ? data.company.business_name : '' }}</p>
                          <p class="f-s-15px mb-1" v-if="inContentTemp('business_name_en')">{{ data.company ? data.company.business_name_en : '' }}</p>
                          <p class="f-s-15px mb-0" v-if="data.company">
                            <span v-if="inContentTemp('cocompany_mpany_country_name')">{{data.company.country_name}}</span>
                            <span v-if="inContentTemp('cocompany_mpany_city_name')">{{(data.company.city_name ? ' - ' + data.company.city_name : '')}}</span>
                            <span>{{(data.company.address_1 ? ' - ' + data.company.address_1 : '')}}</span>
                          </p>
                        </div>
                      </div>
                      <div v-if="data.company && (data.company.business_record || data.company.tax_no)">
                        <h5 class="f-s-15px mb-0" v-if="data.company.business_record && inContentTemp('business_record')">
                          <p class="color-1a5a4a mb-1 d-flex justify-content-between"><span>رقم السجل التجــــاري</span> <span>{{ data.company ? data.company.business_record : '' }}</span> <span style="display: block; direction: ltr;" v-if="is_multi_language_label">CR No.</span></p>
                          <!--                          <p class="mb-0 f-s-15px">{{ data.company ? data.company.business_record : '' }}</p>-->
                        </h5>
                        <h5 class="f-s-15px mb-0" v-if="data.company.tax_no && inContentTemp('tax_no')">
                          <p class="color-1a5a4a mb-1 d-flex justify-content-between"><span>رقم التسجيل الضريبة</span> <span>{{ data.company ? data.company.tax_no : '' }}</span>  <span style="display: block; direction: ltr;" v-if="is_multi_language_label">VAT No.</span></p>
                          <!--                          <p class="mb-0 f-s-15px">{{ data.company ? data.company.tax_no : '' }}</p>-->
                        </h5>
                      </div>

                    </div>
                  </div>

                </div>
                <div class="mt-2 w-100 margin-right-025rem">
                  <div class="d-flex justify-content-between">
                    <span class="color-1a5a4a f-s-15px mb-1"><b>معلومات المشتري</b></span>
                    <span class="color-1a5a4a f-s-15px mb-1" v-if="is_multi_language_label"><b>Buyer information</b></span>
                  </div>
                  <div class="w-60per h-100">
                    <div class="green-cc-3">
                      <div class="d-flex">
                        <span class="par-h"></span>
                        <div>
                          <p class="f-s-15px mb-1" v-if="inContentTemp('fullname')">{{ data.customer ? data.customer.fullname : '' }}</p>
                          <p class="f-s-15px mb-0">{{ data.customer ? (data.customer.country_name) + (data.customer.city_name ? ' - ' + data.customer.city_name : '') + (data.customer.address_1 ? ' - ' + data.customer.address_1 : '') : '' }}</p>
                        </div>
                      </div>
                      <div class="ml-2 mr-2" v-if="data.customer && (data.customer.commercial_register || data.customer.tax_register)">

                        <h5 class="f-s-15px mb-0" v-if="data.customer.commercial_register && inContentTemp('commercial_register')">
                          <p class="color-1a5a4a mb-1 d-flex justify-content-between"><span>رقم السجل التجــــاري</span> <span>{{ data.customer ? data.customer.commercial_register : '' }}</span> <span style="display: block; direction: ltr;" v-if="is_multi_language_label">CR No.</span></p>
<!--                          <p class="mb-0 f-s-15px">{{ data.customer ? data.customer.commercial_register : '' }}</p>-->
                        </h5>
                        <h5 class="f-s-15px mb-0" v-if="data.customer.tax_register && inContentTemp('tax_register')">
                          <p class="color-1a5a4a mb-1 d-flex justify-content-between"><span>رقم التسجيل الضريبي</span> <span>{{ data.customer ? data.customer.tax_register : '' }}</span> <span style="display: block; direction: ltr;" v-if="is_multi_language_label">VAT No.</span></p>
<!--                          <p class="mb-0 f-s-15px">{{ data.customer ? data.customer.tax_register : '' }}</p>-->
                        </h5>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2 d-flex" v-if="data.notes">
                <div class="w-60per">
                  <div class="green-cc-3" style="height: auto;">
                    <span class="color-1a5a4a f-s-15px mb-4 d-flex justify-content-between"><b>الملاحظات</b> <b v-if="is_multi_language_label">Notes</b></span>
                    <p class="f-s-15px mb-1">{{ data.notes }}</p>
                  </div>
                </div>
              </div>
              <div class="mt-4 table-border-radius break-inside-revert">
                <table class="table" style="margin: 0;text-align: center;">
                  <thead>
                  <tr style="background:#ffffff">
                    <th :style="{width: is_custom_product_size_column ? product_size_column_val+'%':'179px'}"> المنتج <span style="display: block;" v-if="is_multi_language_label">Item</span></th>
                    <th> {{getLabelShortcut('unit_price', 'ar')}} <span style="display: block; direction: ltr;" v-if="is_multi_language_label">{{getLabelShortcut('unit_price', 'en')}}</span></th>
                    <th>الكمية <span style="display: block;" v-if="is_multi_language_label">QTY</span></th>
                    <th v-if="inProductTableSetting('unit')">الوحدة <span style="display: block;" v-if="is_multi_language_label">Unit</span></th>
                    <th v-if="inProductTableSetting('discount_calc')">خصومات <span style="display: block;" v-if="is_multi_language_label">Discounts</span></th>
<!--                    <th> المجموع قبل VAT<span style="display: block;" v-if="is_multi_language_label">Total Before VAT</span></th>-->
<!--                    <th> نــسـبـة الضريبة <span style="display: block;" v-if="is_multi_language_label">VAT</span></th>-->
<!--                    <th> قــيــمـة الضريبة <span style="display: block;" v-if="is_multi_language_label">VAT Value</span></th>-->
<!--                    <th> المجموع بعد VAT<span style="display: block;" v-if="is_multi_language_label">Total After VAT</span></th>-->
                    <th v-if="inProductTableSetting('subtotal_after_discount')"> المجموع<span style="display: block;" v-if="is_multi_language_label">Total</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in items_list" :key="index" class="tr">
                    <td style="text-align: start !important;">
<!--                      <span style="width: 150px; display: block; word-wrap: break-word; white-space: normal;">-->
                  {{ row.description  ? row.description  : '' }}
<!--                </span>-->
                    </td>
                    <td>{{ row.unit_price }}</td>
                    <td>{{ row.qty }}</td>
                    <td v-if="inProductTableSetting('unit')">{{ row.unit_name }}</td>
                    <td v-if="inProductTableSetting('discount_calc')">{{ row.discount_calc }}</td>
<!--                    <td>{{ row.subtotal_before_tax }}</td>-->
<!--                    <td>{{ row.tax ? row.tax.value_rate : "0" }}</td>-->
<!--                    <td>{{ row.amount_tax }}</td>-->
<!--                    <td>{{ row.subtotal_after_tax }}</td>-->
                    <td v-if="inProductTableSetting('subtotal_after_discount')">{{ row.subtotal_after_discount }}</td>
                  </tr>
                  <template v-if="items_list && items_list.length < 9 && is_empty_row">
                    <tr v-if="items_list.length < 9" v-for="(row, index) in (8 - items_list.length)" :key="index">
                      <td style="height: 33px;"></td>
                      <td></td>
                      <td></td>
                      <td v-if="inProductTableSetting('unit')"></td>
                      <td v-if="inProductTableSetting('discount_calc')"></td>
                      <td v-if="inProductTableSetting('subtotal_after_discount')"></td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>


<!--              <div class="d-flex justify-content-between mt-2" :class="{'page-break-inside-pdf': type == 'pdf'}">-->
              <div class="d-flex justify-content-between mt-2 page-break-inside-pdf">
                <div style="width: 65%;">
                  <div class="total-border d-flex justify-content-between" v-if="inContentTemp('invoice_subtotal')">
                    <div class="padding-5-20">
                      <h3 class="mb-0 rtl-dir color-1a5a4a width-100 d-flex f-s-15px"><span>المجموع</span> <span v-if="is_multi_language_label" style="display: block; direction: ltr; padding: 0 15px;text-align: left;">Total</span></h3>
                    </div>
                    <div class="padding-5-20 d-flex f-s-15px"><span class="sum-sum"><b class="f-s-15px">{{ formatNumber(data.invoice_total_before_taxes) }}</b></span> <span class="color-1a5a4a">{{ data.currency_name }}</span></div>
                  </div>
                  <div class="total-border d-flex mt-1 justify-content-between" v-if="inContentTemp('invoice_total_discount')">
                    <div class="padding-5-20">
                      <h3 class="mb-0 rtl-dir color-1a5a4a width-100 d-flex f-s-15px"><span>مجموع الخصومات</span> <span v-if="is_multi_language_label" style="display: block; direction: ltr; padding: 0 15px;text-align: left;">Total Discount</span></h3>
                    </div>
                    <div class="padding-5-20 d-flex f-s-15px"><span class="sum-sum"><b class="f-s-15px">{{ data.invoice_total_discount }}</b></span> <span class="color-1a5a4a">{{ data.currency_name }}</span></div>
                  </div>
                  <div class="total-border d-flex mt-1 justify-content-between" v-if="inContentTemp('shipping_fees')">
                    <div class="padding-5-20">
                      <h3 class="mb-0 rtl-dir color-1a5a4a width-100 d-flex f-s-15px"><span>مجموع الشحن</span> <span v-if="is_multi_language_label" style="display: block; direction: ltr; padding: 0 15px;text-align: left;">Total Shipping</span></h3>
                    </div>
                    <div class="padding-5-20 d-flex f-s-15px"><span class="sum-sum"><b class="f-s-15px">{{ data.shipping_fees }}</b></span> <span class="color-1a5a4a">{{ data.currency_name }}</span></div>
                  </div>

<!--                  <div class="total-border d-flex mt-1 justify-content-between" v-if="inContentTemp('invoice_total_taxes')">-->
<!--                    <div class="padding-5-20">-->
<!--                      <h3 class="mb-0 rtl-dir color-1a5a4a width-100 d-flex f-s-15px"><span>ضريبة القيمة المضافة 15%</span> <span v-if="is_multi_language_label" style="display: block; direction: ltr; padding: 0 15px;text-align: left;">VAT 15%</span></h3>-->
<!--                    </div>-->
<!--                    <div class="padding-5-20 d-flex f-s-15px"><span class="sum-sum"><b class="f-s-15px">{{ data.invoice_total_taxes }}</b></span> <span class="color-1a5a4a">{{ data.currency_name }}</span></div>-->
<!--                  </div>-->
                  <div class="total-border d-flex mt-1 justify-content-between" v-if="inContentTemp('invoice_total_after_taxes')">
                    <div class="padding-5-20">
                      <h3 class="mb-0 rtl-dir color-1a5a4a width-100 d-flex f-s-15px"><span>المجموع الكلي</span> <span v-if="is_multi_language_label" style="display: block; direction: ltr; padding: 0 15px;text-align: left;">Total after discount</span></h3>
                    </div>
                    <div class="padding-5-20 d-flex f-s-15px"><span class="sum-sum"><b class="f-s-15px">{{ formatNumber(data.invoice_total) }}</b></span> <span class="color-1a5a4a">{{ data.currency_name }}</span></div>
                  </div>

                  <div class="mt-1 d-flex" v-if="is_payment_details && data.payments && data.payments.length">
                    <div class="w-60per">
                      <div class="green-cc-3" style="padding: 2px 5px;height: 100%;">
                        <h5 class="color-1a5a4a f-s-15px mb-2 d-flex justify-content-between"><b>المدفوعات</b> <b v-if="is_multi_language_label">Payments</b></h5>
                        <div>
                          <h6 style="display: inline-block;margin-left: 5px;" v-for="(row, index) in data.payments" :key="index" v-if="row.amount != 0 && row.amount != null" > {{ row.payment_method }} {{ formatNumber(row.amount) }} {{ row.currency_name }} </h6>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center total-border mt-1"  v-if="inContentTemp('word_invoice_total') || inContentTemp('word_invoice_total_en')" style="padding: 2px 5px;border-radius: 10px;">
                    <p class="d-flex justify-content-between mb-2"><span>المبلغ بالكلمات</span> <span v-if="is_multi_language_label" style="margin: 0 0.5rem">Amount in words</span></p>
                    <p class="d-flex justify-content-between mb-0">
                      <span v-if="data.word_invoice_total && inContentTemp('word_invoice_total')">{{data.word_invoice_total}}</span>
                      <span v-if="data.word_invoice_total_en && inContentTemp('word_invoice_total_en')">{{data.word_invoice_total_en}}</span>
                    </p>
                  </div>
                  <!--          <div class="mt-1 d-flex" v-if="is_payment_details && data.payments && data.payments.length">-->
                  <!--            <div class="w-60per">-->
                  <!--              <div class="green-cc-3" style="padding: 2px 5px;">-->
                  <!--                <h5 class="color-1a5a4a f-s-15px mb-2 d-flex justify-content-between"><b>المدفوعات</b> <b v-if="is_multi_language_label">Payments</b></h5>-->
                  <!--                <div class="d-flex">-->
                  <!--                  <span class="par-h"></span>-->
                  <!--                  <div>-->
                  <!--                    <tr v-for="(row, index) in data.payments" :key="index">-->
                  <!--                      <ul class="list-unstyled">-->
                  <!--                        <li><span class="color-1a5a4a">المبلغ</span> <span v-if="is_multi_language_label" class="color-1a5a4a">Amount</span> <b class="f-s-22px">{{ formatNumber(row.amount) }}</b></li>-->
                  <!--                        <li><span class="color-1a5a4a">العملة</span> <span v-if="is_multi_language_label" class="color-1a5a4a">Currency</span> <b class="f-s-20px">{{ row.currency_name }} </b></li>-->
                  <!--                        <li><span class="color-1a5a4a"> طريقة الدفع</span> <span v-if="is_multi_language_label" class="color-1a5a4a"> Payment Method</span> <b class="f-s-20px">{{ row.payment_method }}</b></li>-->
                  <!--                      </ul>-->
                  <!--                      <hr>-->
                  <!--                    </tr>-->
                  <!--                  </div>-->
                  <!--                </div>-->
                  <!--              </div>-->
                  <!--            </div>-->
                  <!--          </div>-->


                </div>
<!--                <div class="text-center total-border"  v-if="inContentTemp('word_invoice_total') || inContentTemp('word_invoice_total_en')" style="display: block;width: 35%;margin-right: 0.5rem;padding: 5px;">-->
<!--                  <p class="d-flex justify-content-between"><span>المبلغ بالكلمات</span> <span v-if="is_multi_language_label" style="margin: 0 0.5rem">Amount in words</span></p>-->
<!--                  <p v-if="data.word_invoice_total && inContentTemp('word_invoice_total')" style="margin-bottom: 0;text-align: right;padding-bottom: 15px;">{{data.word_invoice_total}}</p>-->
<!--                  <p v-if="data.word_invoice_total_en && inContentTemp('word_invoice_total_en')" style="margin-bottom: 0;text-align: left;">{{data.word_invoice_total_en}}</p>-->
<!--                </div>-->
              </div>
<!--              <div v-if="type == 'print'" class="d-flex justify-content-between mt-2" :class="{'page-break-inside-pdf': type == 'pdf'}">-->
<!--&lt;!&ndash;                <div class="text-center total-border h-auto" style="padding: 5px;">&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="qr-border text-center" style="margin: 10px auto 3px auto; width: 152px;">&ndash;&gt;-->
<!--&lt;!&ndash;                    <qr-code v-if="data.data_qr_code" :text="data.data_qr_code" :size="size"></qr-code>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--                <div v-if="is_signature" class="d-flex">-->
<!--                  <div class="total-border h-100" style="padding: 5px;position: relative;margin: 0 0.25rem;width: 145px;">-->
<!--                    <p class="m-0 f-s-15px text-center">Received By</p>-->
<!--                    <div style="position: absolute;bottom: 0;text-align: center;width: 100%;">-->
<!--                      <p class="m-0 f-s-15px">التوقيع مع الختم</p>-->
<!--                      <p class="m-0 f-s-15px">Signed with stamp</p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="total-border h-100" style="padding: 5px;position: relative;margin: 0 0.25rem;width: 145px;">-->
<!--                    <p class="m-0 f-s-15px text-center">Authorized Signatory</p>-->
<!--                    <div style="position: absolute;bottom: 0;text-align: center;width: 100%;">-->
<!--                      <p class="m-0 f-s-15px">التوقيع مع الختم</p>-->
<!--                      <p class="m-0 f-s-15px">Signed with stamp</p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div v-if="is_banking_details" class="total-border p-2 h-100" style="margin-right: 0.25rem;width: 666px;">-->
<!--                  <p class="d-flex justify-content-between"><span>التفاصيل المصرفية</span> <span v-if="is_multi_language_label" style="margin: 0 0.5rem">Bank Details</span></p>-->
<!--                  <div class="acc-bank-border">-->
<!--                    <p class="color-1a5a4a mb-0 d-flex justify-content-between f-s-15px"><span>اسم الحساب</span>   <span style="display: block; direction: ltr;" v-if="is_multi_language_label">Account Name</span></p>-->
<!--                    <p class="mb-0 f-s-15px">{{ data.company ? data.company.account_holder_name : '' }}</p>-->
<!--                  </div>-->
<!--                  <div class="d-flex justify-content-between acc-bank-border">-->
<!--                    <div>-->
<!--                      <p class="color-1a5a4a mb-0 d-flex justify-content-between f-s-15px"><span style="margin-left: 5px;margin-right: 5px;"> البنك </span>   <span v-if="is_multi_language_label"> Bank </span></p>-->
<!--                      <p class="mb-0 f-s-15px">{{ data.company ? data.company.bank_name : '' }}</p>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <p class="color-1a5a4a mb-0 d-flex justify-content-between f-s-15px"><span style="margin-left: 5px;margin-right: 5px;"> الفرع </span>   <span  v-if="is_multi_language_label"> Branch </span></p>-->
<!--                      <p class="mb-0 f-s-15px">{{ data.company ? data.company.bank_branch : '' }}</p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="acc-bank-border">-->
<!--                    <p class="color-1a5a4a mb-0 d-flex justify-content-between f-s-15px"><span>الحساب</span>   <span style="display: block; direction: ltr;" v-if="is_multi_language_label">Account</span></p>-->
<!--                    <p class="mb-0 f-s-15px">{{ data.company ? data.company.account_holder_number : '' }}</p>-->
<!--                  </div>-->
<!--                  <div class="acc-bank-border">-->
<!--                    <p class="color-1a5a4a mb-0 d-flex justify-content-between f-s-15px"><span>الايبان</span>   <span style="display: block; direction: ltr;" v-if="is_multi_language_label">IBAN</span></p>-->
<!--                    <p class="mb-0 f-s-15px">{{ data.company ? data.company.iban : '' }}</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <main-footer :inner-data="data" :data-footer="{is_multi_language_label: is_multi_language_label, is_banking_details: is_banking_details, is_signature: is_signature}"></main-footer>

            </div>
          </td>
        </tr>
        </tbody>
        <tfoot :class="is_repeated_footer ? 'report-footer' : 'no-report-footer'">
        <tr>
          <td class="report-footer-cell">
            <div class="footer-info footer-print" :class="{'page-break-inside-pdf': type == 'pdf'}">
              <div v-if="data.pos_policy">
                <p v-if="is_break_before_footer"><span :style="'border: 1px solid;width: 70%;height: 0px;display: block;margin: 20px auto 0;border-color:'+footer_font_color"></span></p>
                <div class="mb-0 ql-editor" style="float: right;text-align: right;width: 100%;height: auto;padding: 0;"  v-html="data.pos_policy"></div>
              </div>
            </div>
          </td>
        </tr>
        </tfoot>
      </table>




    </div>
  </div>




</template>

<script>
import ApiService from "@/core/services/api.service";

import VueQRCodeComponent from 'vue-qrcode-component';
import MainHeader from "@/view/content/printing-heads/MainHeader.vue";
import html2pdf from "html2pdf.js";
import MainFooter from "@/view/content/printing-footers/MainFooter.vue";
import invoicesLabelOfItems from "@/core/config/mix/invoicesLabelOfItems";


export default {
  name: "print-general-sales-invoice",
  components: {
    MainFooter,
    MainHeader,
    'qr-code': VueQRCodeComponent
  },

  data() {
    return {
      lang: this.$i18n.locale,
      lang_dir:{'direction': 'ltr','text-align': 'left'},
      mainRoute: 'sales/general_sales_invoices',

      data: {},
      items_list: [],

      templateMainRoute: 'settings/print_setting/type',
      template_for: 'GeneralSales',
      template: null,
      content_temp: [],

      idEditing: this.$route.params.sales_invoice_id ? this.$route.params.sales_invoice_id : null,
      status: this.$route.params.status ? this.$route.params.status : null,
      items_total: 0,
      total: 0,
      show: false,
      is_show_price: (this.$route.query.is_show_price == 0) ? false : true,
      type: this.$route.params.type ? this.$route.params.type : null,
      size: 140,
      is_multi_language_label: false,

      is_break_before_footer: false,
      is_payment_details: false,
      is_banking_details: false,
      footer_font_color: '#000',
      is_repeated_header: false,
      is_repeated_footer: false,
      is_empty_row: false,
      is_signature: false,
      product_table_setting: [],
      label_shortcut_details: [],
      is_use_label_shortcut: false,
      is_custom_product_size_column: false,
      product_size_column_val: 37,
    }
  },
  computed: {
    rel_logo: function () {
      if(this.data && this.data.company && this.data.company.logo_url){
        return this.data.company.logo_url;
      }else {
        // return window.location.origin + '/media/logos/sajlha_logo_print.png';
        return '';
      }

    },
  },
  watch: {
    items_list: {
      handler(val) {
        if (val.length > 0) {
          this.items_total = this.$_.sumBy(this.items_list, 'subtotal');
          this.items_total = this.items_total ? this.items_total : 0;
          this.total = this.items_total;

        }
      },
      deep: true
    }
  },
  methods: {
    ...invoicesLabelOfItems,
    formatNumber(number) {
        // Format the number with commas if it's greater than 5 digits
        return number > 9999 ? new Intl.NumberFormat().format(number) : number;
    },
    printInvoice() {
      let _id = 'invoice';
      this.exportPDF(_id);
    },
    completedForPrint(){
      let imgs = document.images,
          len = imgs.length,
          counter = 0;

      [].forEach.call( imgs, function( img ) {
        if(img.complete)
          incrementCounter();
        else
          img.addEventListener( 'load', incrementCounter, false );
      } );
      function incrementCounter() {
        counter++;
        if ( counter === len ) {
          window.print();
          window.close();
        }
      }

    },
    exportPDF(_id) {
      let that = this;
      if (that.type == 'pdf') {
        const opt = { pagebreak: {avoid: '.tr', mode: ['css']}, margin: [5, 0, 5, 0], filename: 'invoice.pdf', image: { type: 'jpeg', quality: 2 }, html2canvas: {useCORS: true, scrollY: 0 }, jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' } };
        let _html2pdf = html2pdf().from(document.getElementById(_id)).set(opt).output('pdfobjectnewwindow');
      }else {
        this.completedForPrint();
      }

      // let that = this;
      // let pdf = jsPdfExportTajawal("p", "px", 'a4');
      //
      // pdf.html(document.getElementById(_id), {
      //   html2canvas: {
      //     scale: 0.5,
      //   },
      //   callback: function (pdf) {
      //     if (that.type == 'pdf') {
      //       pdf.save('invoice');
      //     } else {
      //       pdf.autoPrint();
      //       pdf.output('dataurlnewwindow');
      //     }
      //   }
      // });
    },
    async getData() {
      let _id = this.idEditing;
      await ApiService.get(`${this.mainRoute}/${_id}/print`).then((response) => {

        this.data = response.data.data;
        this.items_list = response.data.data.items_list;
        this.total = response.data.data.total;
        this.show = true;
      });
    },
    async printSetting() {
      await ApiService.get(`${this.templateMainRoute}/${this.template_for}`).then((response) => {
        this.template = response.data.data;
        this.content_temp = response.data.data.content_temp;
        this.product_table_setting = response.data.data.product_table_setting ? response.data.data.product_table_setting : [];
        this.is_multi_language_label = response.data.data.is_multi_language_label ? response.data.data.is_multi_language_label : false;
        this.is_break_before_footer = response.data.data.is_break_before_footer ? response.data.data.is_break_before_footer : false;
        this.is_payment_details = response.data.data.is_payment_details ? response.data.data.is_payment_details : false;
        this.is_banking_details = response.data.data.is_banking_details ? response.data.data.is_banking_details : false;
        this.is_repeated_header = response.data.data.is_repeated_header ? response.data.data.is_repeated_header : false;
        this.is_repeated_footer = response.data.data.is_repeated_footer ? response.data.data.is_repeated_footer : false;
        this.is_empty_row = response.data.data.is_empty_row ? response.data.data.is_empty_row : false;
        this.is_signature = response.data.data.is_signature ? response.data.data.is_signature : false;
        this.footer_font_color = response.data.data.footer_font_color ? response.data.data.footer_font_color : '#000';

        this.label_shortcut_details = response.data.data.label_shortcut_details ? response.data.data.label_shortcut_details : [];
        this.is_use_label_shortcut = response.data.data.is_use_label_shortcut ? response.data.data.is_use_label_shortcut : false;
        this.is_custom_product_size_column = response.data.data.is_custom_product_size_column ? response.data.data.is_custom_product_size_column : false;
        this.product_size_column_val = response.data.data.product_size_column_val ? response.data.data.product_size_column_val : 37;

      });
    },
    getLabelShortcut(_slug, lang){
      let _is_use_label = this.is_use_label_shortcut;
      // let _is_use_label = this.is_multi_language_label && this.is_custom_product_size_column;
      return this.getLabelShortcutFromSettingsPrint( _slug, lang, this.label_shortcut_details, _is_use_label);
    },
    inContentTemp(content) {
      if (this.content_temp.includes(content))
        return true;
      else
        return false;
    },
    inProductTableSetting(content) {
      if (this.product_table_setting.includes(content))
        return true;
      else
        return false;
    },
  },
  mounted() {
    let promise = this.getData();
    let promise1 = this.printSetting();
    Promise.all([promise, promise1]).then(() => {
      this.printInvoice();
    });
    // window.print();
  }
}
</script>

<style>
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.title-1 {
  font-size: 39px;
  color: #1a5a4a;
}

.title-2 {
  font-size: 30px;
  color: #8898b2;
  padding-top: 8px;
}

.logo-w {
  width: 162px;
  margin-left: 77px;
}

.color-1a5a4a {
  color: #1a5a4a;
}

.green-cc {
  border: 2px solid #1a5a4a;
  padding: 2px 8px;
  border-radius: 10px;
  text-align: center;
}

.green-cc-3 {
  border: 2px solid #1a5a4a;
  padding: 10px 15px;
  border-radius: 10px;
  height: 86%;
}

.green-cc-2 {
  border: 2px solid #1a5a4a;
  padding: 6px 15px;
  border-radius: 10px;
}

.f-s-22px {
  font-size: 22px;
}

.f-s-19px {
  font-size: 19px;
}

.h-16px {
  line-height: 1.3;
}

.f-s-20px {
  font-size: 20px;
  font-weight: 400;
}

.f-s-15px {
  font-size: 15px;
  font-weight: 400;
}

.w-60per {
  width: 100%;
  display: inline-block;
}

.w-40per {
  width: 34%;
  display: inline-block;
}

.padding-25-15 {
  padding: 21px 15px !important;
}

.padding-b-15 {
  margin-bottom: 15px;
}

.fs-12 {
  font-size: 12px !important;
}

.word-spacing-4px {
  word-spacing: 4px;
}

.par-h {
  width: 1.5px;
  margin-left: 10px;
  background-color: #8898b2;
  display: block;
}

.qr-border {
  border: 2px solid #1a5a4a !important;
  padding: 5px;
  border-radius: 5px;
}

.total-border {
  border: 2px solid #1a5a4a !important;
  border-radius: 5px;
}

.w-330 {
  width: 55%;
}

.padding-5-20 {
  padding: 2px 5px !important;
}

.border-r-2 {
  border-right: 2px solid !important;
}

.rtl-dir {
  direction: rtl !important;
  float: right !important;
  display: flex !important;
}

#invoice {
  font-family: "AlHurra";
  width: 235mm;
  font-weight: 400 !important;
}

/*.font-family-Tajawal {*/
/*  font-family: "Roboto", sans-serif !important;*/
/*  word-spacing: initial !important;*/
/*}*/
/*#invoice * {*/
/*  word-spacing: 1px !important;*/
/*}*/

/*#invoice .space-word-2{*/
/*  word-spacing: 10px !important;*/
/*}*/
/*#invoice .space-word-15{*/
/*  word-spacing: 26px !important;*/
/*}*/
/*#invoice .space-latter-2{*/
/*  letter-spacing: 1px !important;*/
/*}*/
/*table, thead, tr, th, tbody, td {*/
/*  border: 2px solid #1a5a4a !important;*/
/*}*/

table thead tr th {
  border-bottom: 2px solid #1a5a4a !important;
}
table tbody {
  font-size: 14px !important;
}

table thead tr th:not(:last-child) {
  border-left: 2px solid #1a5a4a !important;
}

table tbody tr td:not(:last-child) {
  border-left: 2px solid #1a5a4a !important;
}

.table-border-radius {
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid #1a5a4a !important;
}

tbody td, tbody tr {
  border-bottom: 0 !important;
  border-top: 0 !important;
}

.invoice-box {
  /*background-color: #fff;*/

  /*margin: auto;*/
  /*padding: 30px;*/
  border: 1px solid #fff;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #000;


  /* padding: 65px 40px; */
  background-color: rgb(255, 255, 255);
  width: 210mm !important;
  height: auto;
  /* margin: 20px auto; */
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 5px 20px;

}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  /* padding: 5px; */
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr td:nth-child(3) {
  text-align: right;
}

.invoice-box table tr.top td.title {
  font-size: 45px;
  line-height: 45px;
  color: #000;
}

.sum-sum {
  text-align: center;
  margin-left: 15px;
  min-width: 60px;
}

.padd {
  padding-bottom: 100px;

}

.invoice-box table tr.information td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  border: 1px solid #000 !important;
  background: #CAD3C8;
  border-bottom: 1px solid #fff;
  font-weight: bold;
}

.border {
  border: 1px solid #000 !important;
  padding: 5px;
}

.color {
  background-color: #CAD3C8;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/

.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
  text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}

.table thead th {
  font-size: 15px !important;
  font-weight: 400 !important;
  color: #1a5a4a;
  vertical-align: middle;
}

.dir-ltr-w-100 {
  direction: ltr;
  float: left;
  text-align: center;
  width: 100%;
}

.width-100 {
  width: 100% !important;
}
.table thead th, .table thead td {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.table th, .table td {
  padding: 0.3rem;
}
.acc-bank-border{
  margin-bottom: 3px;
  padding-bottom: 3px;
  border-bottom: 2px solid #1a5a4a;
}
.table tbody tr:not(:last-child){
  border-bottom: 2px solid #1a5a4a !important;
}
body > *{
  float: right !important;
  direction: rtl !important;
  text-align: right;
}
html{
  direction: rtl !important;
}
.invoice-box:not(.pdf) .main > div{
  page-break-inside: avoid;
}
</style>